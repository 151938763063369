import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApiEndpoint from './api-endpoint';
import ApiEndpointDetail from './api-endpoint-detail';
import ApiEndpointUpdate from './api-endpoint-update';
import ApiEndpointDeleteDialog from './api-endpoint-delete-dialog';

const ApiEndpointRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApiEndpoint />} />
    <Route path="new" element={<ApiEndpointUpdate />} />
    <Route path=":id">
      <Route index element={<ApiEndpointDetail />} />
      <Route path="edit" element={<ApiEndpointUpdate />} />
      <Route path="delete" element={<ApiEndpointDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApiEndpointRoutes;
