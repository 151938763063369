import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AiChat from './ai-chat';
import AiChatDetail from './ai-chat-detail';
import AiChatUpdate from './ai-chat-update';
import AiChatDeleteDialog from './ai-chat-delete-dialog';

const AiChatRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AiChat />} />
    <Route path="new" element={<AiChatUpdate />} />
    <Route path=":id">
      <Route index element={<AiChatDetail />} />
      <Route path="edit" element={<AiChatUpdate />} />
      <Route path="delete" element={<AiChatDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AiChatRoutes;
