import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Citations from './citations';
import CitationsDetail from './citations-detail';
import CitationsUpdate from './citations-update';
import CitationsDeleteDialog from './citations-delete-dialog';

const CitationsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Citations />} />
    <Route path="new" element={<CitationsUpdate />} />
    <Route path=":id">
      <Route index element={<CitationsDetail />} />
      <Route path="edit" element={<CitationsUpdate />} />
      <Route path="delete" element={<CitationsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CitationsRoutes;
