import { getEntities } from 'app/entities/user-prompt/user-prompt.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CardGroup, Card, CardBody, CardTitle, CardText, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getSession } from 'app/shared/reducers/authentication';
import { AUTHORITIES } from 'app/config/constants';

interface Props {
  cardCols?: string;
  handleViews?: any;
  id?: any;
}
export const UserpromptListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const { id } = useParams<'id'>();

  const isNew = id === undefined;
  var dataList = useAppSelector(state => state.userPrompt.entities);
  const loading = useAppSelector(state => state.userPrompt.loading);
  const totalItems = useAppSelector(state => state.userPrompt.totalItems);

  const [dropdownOpenApp, setDropdownOpenApp] = useState('');

  const getAllEntities = () => {};

  useEffect(() => {
    if (isNew) {
      dispatch(getEntities({}));
    } else {
      dispatch(
        getEntities({
          query: `Id.in=${id}`,
        })
      );
    }
  }, [id]);

  let entityFields = ['PromptText', 'promptPersona'];

  return (
    <>
      <CardGroup>
        {dataList.length > 0
          ? dataList.map((product, i) => (
              <Card
                style={
                  props.cardCols === '2'
                    ? {
                        minWidth: '49%',
                        margin: '.5%',
                      }
                    : {
                        minWidth: '24%',
                        margin: '.5%',
                      }
                }
                className="text-decoration-none"
              >
                <img alt="Sample" src={'https://picsum.photos/300/200'} />
                {
                  <CardBody>
                    <CardTitle tag="h5">{product?.PromptText}</CardTitle>
                  </CardBody>
                }
                <div className="btn-group flex-btn-group-container">
                  <></>
                </div>
              </Card>
            ))
          : !loading && (
              <div className="alert alert-warning">
                <h2>No UserPrompt found</h2>
              </div>
            )}
      </CardGroup>
    </>
  );
};

export default UserpromptListView;
