import UserpromptListView from './UserpromptListView';
import React, { useEffect, useState } from 'react';
import { getEntity } from '../../../app/entities/user-prompt/user-prompt.reducer';
import BreadCrumbTemplate from 'app/modules/breadCrumbTemplate';
import { Route, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
interface Props {
  handleViews?: any;
  id?: any;
}

export const Conversation = (props: Props) => {
  const [showViews, setShowViews] = useState('');
  const [id, setId] = useState(undefined);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<'id'>().id;
  const userPrompt = useAppSelector(state => state.userPrompt.entity);

  useEffect(() => {
    dispatch(getEntity(params));
  }, [params]);

  const handleViews = (type, id) => {
    setShowViews(type);
    setId(id);
  };

  return (
    <div>
      <BreadCrumbTemplate currentPath={location.pathname} paramsName={userPrompt.PromptText} />
      <Row>
        <Col md="12" className="mx-5">
          <h1>Conversation</h1>
        </Col>

        <Col md="12">
          <UserpromptListView handleViews={handleViews} />
        </Col>
        <span id="custom-components" />
      </Row>
    </div>
  );
};

export default Conversation;
