import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { renderToString } from 'react-dom/server';

export const GeneratedmessageDetailsView = props => {
  const [airesponse, setAiresponse] = useState<any>();
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  useEffect(() => {
    if (airesponse != props.userConversationAI) {
      setAiresponse(props.userConversationAI);
      scrollToBottom();
    }
    if (props.userConversationAI == false) {
      setAiresponse(false);
    }
  }, [props.userConversationAI]);

  return (
    <Row className="content-paragraph" style={props.showTemplates ? { display: 'none' } : { display: 'block' }}>
      {
        <Col>
          <ListGroup>
            <div className="inner-msg" dangerouslySetInnerHTML={{ __html: props.userConversation }} />
            {airesponse && (
              <div
                className="inner-msg"
                dangerouslySetInnerHTML={{
                  __html: renderToString(
                    <Markdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        a: ({ node, ...props }) => {
                          return (
                            <a {...props} target="_blank">
                              {props.children}
                            </a>
                          );
                        },
                      }}
                    >
                      {airesponse}
                    </Markdown>
                  ),
                }}
              />
            )}

            {/* {airesponse && <Markdown remarkPlugins={[remarkGfm]}>{airesponse}</Markdown>} */}
            {}
          </ListGroup>
          {props.loading ? (
            <p>
              <img className="cpapilot-loading-img" src="content/images/loading.gif" alt="Logo" />
            </p>
          ) : (
            ''
          )}
        </Col>
      }
      {/* This div will be used to scroll to */}
      <div ref={messagesEndRef}></div>
    </Row>
  );
};

export default GeneratedmessageDetailsView;
