import dayjs from 'dayjs';
import { IGeneratedMessage } from 'app/shared/model/generated-message.model';
import { IUser } from 'app/shared/model/user.model';
import { IApi } from 'app/shared/model/api.model';
import { AiSystem } from 'app/shared/model/enumerations/ai-system.model';
import { ConversationStatus } from 'app/shared/model/enumerations/conversation-status.model';

export interface IAiChat {
  id?: number;
  aiModel?: string | null;
  title?: string | null;
  aiSystem?: AiSystem | null;
  userRating?: number | null;
  userRatingReason?: string | null;
  context?: string | null;
  examples?: string | null;
  externalChatId?: string | null;
  favourite?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  deletedDate?: string | null;
  status?: ConversationStatus | null;
  generatedMessages?: IGeneratedMessage[] | null;
  user?: IUser | null;
  api?: IApi | null;
}

export const defaultValue: Readonly<IAiChat> = {
  favourite: false,
};
