import '../../../../../../app/shared/layout/header/header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Button } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand } from '../../../../../../app/shared/layout/header/header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../../../../../../app/shared/layout/menus';
import { useAppDispatch, useAppSelector } from '../../../../../../app/config/store';
import { setLocale } from '../../../../../../app/shared/reducers/locale';
import { createEntity } from '../../../entities/ai-chat/ai-chat.reducer';
import { setSidebarToggle } from '../../../entities/sidebar/sidebar.reducer';
import {
  setShowTemplates,
  setUserOldConversationId,
  setIsOpenChatList,
  setIsOpenFavourites,
  setUserOldConversation,
  setMobileViewNewChatButtonClicked,
} from '../../../entities/chat-dashboard/chat-dashboard.reducer';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarOpen = useAppSelector(state => state.sidebar.sidebarOpen);
  const dispatch = useAppDispatch();

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  //const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleMenu = () => {
    // setMenuOpen(!menuOpen);
    dispatch(setSidebarToggle(!sidebarOpen));
  };
  const onStartNewConversation = async () => {
    dispatch(createEntity({}));
    dispatch(setShowTemplates(true));
    dispatch(setUserOldConversationId(null));
    const now = new Date();
    const uniqueStr = now.toISOString().replace(/[-:.TZ]/g, '');
    dispatch(setMobileViewNewChatButtonClicked(uniqueStr));
    // dispatch(setUserOldConversation(''));
    dispatch(setIsOpenFavourites(false));
    dispatch(setIsOpenChatList(true));
  };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        {/* <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            <Home />
            {props.isAuthenticated && <EntitiesMenu />}
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse> */}
        <div className="d-flex justify-content-end">
          <Button color="warning" className="float-right" onClick={onStartNewConversation}>
            <FontAwesomeIcon icon={'plus'} />
          </Button>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
