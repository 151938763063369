import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/ai-chat">
        <Translate contentKey="global.menu.entities.aiChat" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/generated-message">
        <Translate contentKey="global.menu.entities.generatedMessage" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-prompt">
        <Translate contentKey="global.menu.entities.userPrompt" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/api">
        <Translate contentKey="global.menu.entities.api" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/api-endpoint">
        <Translate contentKey="global.menu.entities.apiEndpoint" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/api-manager">
        <Translate contentKey="global.menu.entities.apiManager" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-subcription">
        <Translate contentKey="global.menu.entities.userSubcription" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/subscription-limit">
        <Translate contentKey="global.menu.entities.subscriptionLimit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/citations">
        <Translate contentKey="global.menu.entities.citations" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
