import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-subcription.reducer';

export const UserSubcriptionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userSubcriptionEntity = useAppSelector(state => state.userSubcription.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userSubcriptionDetailsHeading">
          <Translate contentKey="cpapilotApp.userSubcription.detail.title">UserSubcription</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="cpapilotApp.userSubcription.active">Active</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="orderId">
              <Translate contentKey="cpapilotApp.userSubcription.orderId">Order Id</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.orderId}</dd>
          <dt>
            <span id="activeplan">
              <Translate contentKey="cpapilotApp.userSubcription.activeplan">Activeplan</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.activeplan}</dd>
          <dt>
            <span id="planStartDate">
              <Translate contentKey="cpapilotApp.userSubcription.planStartDate">Plan Start Date</Translate>
            </span>
          </dt>
          <dd>
            {userSubcriptionEntity.planStartDate ? (
              <TextFormat value={userSubcriptionEntity.planStartDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="planEndDate">
              <Translate contentKey="cpapilotApp.userSubcription.planEndDate">Plan End Date</Translate>
            </span>
          </dt>
          <dd>
            {userSubcriptionEntity.planEndDate ? (
              <TextFormat value={userSubcriptionEntity.planEndDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="planLimit">
              <Translate contentKey="cpapilotApp.userSubcription.planLimit">Plan Limit</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.planLimit}</dd>
          <dt>
            <span id="trial">
              <Translate contentKey="cpapilotApp.userSubcription.trial">Trial</Translate>
            </span>
          </dt>
          <dd>{userSubcriptionEntity.trial ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.userSubcription.internalUser">Internal User</Translate>
          </dt>
          <dd>{userSubcriptionEntity.internalUser ? userSubcriptionEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-subcription" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-subcription/${userSubcriptionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserSubcriptionDetail;
