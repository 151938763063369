import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserSubcription from './user-subcription';
import UserSubcriptionDetail from './user-subcription-detail';
import UserSubcriptionUpdate from './user-subcription-update';
import UserSubcriptionDeleteDialog from './user-subcription-delete-dialog';

const UserSubcriptionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserSubcription />} />
    <Route path="new" element={<UserSubcriptionUpdate />} />
    <Route path=":id">
      <Route index element={<UserSubcriptionDetail />} />
      <Route path="edit" element={<UserSubcriptionUpdate />} />
      <Route path="delete" element={<UserSubcriptionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserSubcriptionRoutes;
