export enum Persona {
  AISalesAssistant = 'Standard',
  BusinessCasual = 'Business Casual',
  BusinessFormal = 'Business Formal',
  Casual = 'Casual',
  Funny = 'Funny',
  Engaging = 'Engaging',
  Analytical = 'Analytical',
  Technical = 'Technical',
  Brief = 'Brief',
  Creative = 'Creative',
}
