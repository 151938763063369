import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { setShowExtraPrompts, setSelectedPrompt, setIsOpenChatList } from '../../entities/chat-dashboard/chat-dashboard.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import PromptCarousel from './PromptCarousel';

const categories = {
  All: [
    "Replace with your client's unique situation: Advise on the tax implications for a client who sold a rental property in 2023. The property was purchased in 2018 for $250,000 and sold for $400,000. The client has made $30,000 in capital improvements and incurred $15,000 in selling expenses. Additionally, they have been depreciating the property since purchase. How should we calculate the capital gains tax, account for depreciation recapture, and what are the potential deductions or credits that could apply in this situation?",
    'Replace details with your situation: My client and her husband both have W-2 jobs. They have a side business. The client also sold RSU’s through her brokerage. Their child is in college and earned a few thousand dollars through work study. Create a comprehensive checklist of documents and information I will require to prepare and file their 2023 federal income tax return that I can send to the client.',
    'Provide a concise summary of the key points of IRS Publication 946 on How to Depreciate Property, particularly focusing on the changes to depreciation limits and methods for the 2023 tax year?',
    'I have a client who is considering X. Here are the details of the situation. A. B. C. Can you provide an analysis?',
    'Give me a table describing the differences between Section 179 and bonus depreciation',
    'Draft a video script about X',
    'Create a step-by-step guideline for tax accountants to assist their clients in determining eligibility and calculating the Home Office Deduction using the Simplified Method for the 2023 tax year.',
    'Can you provide a list of questions that I can answer so you can give me a complete analysis about my client who is considering X, Y and Z.',
    'Please draft an email to clients explaining the importance of X',
    'Draft a reply to this email from my client:',
    "My client is considering changing corporate structure from X to Y during the 2024 tax year. Can you provide a list of pros and cons for this business structure change, specifically focusing on the tax implications and how it might affect the client's personal tax liability? Ask me any follow up questions that will help you provide a complete answer.",
    'Provide an outline for a course about X.',
    'Draft a facebook post for the topic X',
    'Draft a LinkedIn Post for the topic X',
    'Creating a course outline for X topic',
  ],
  Analysis: [
    "Replace with your client's unique situation: Advise on the tax implications for a client who sold a rental property in 2023. The property was purchased in 2018 for $250,000 and sold for $400,000. The client has made $30,000 in capital improvements and incurred $15,000 in selling expenses. Additionally, they have been depreciating the property since purchase. How should we calculate the capital gains tax, account for depreciation recapture, and what are the potential deductions or credits that could apply in this situation?",
    'Replace details with your situation: My client and her husband both have W-2 jobs. They have a side business. The client also sold RSU’s through her brokerage. Their child is in college and earned a few thousand dollars through work study. Create a comprehensive checklist of documents and information I will require to prepare and file their 2023 federal income tax return that I can send to the client.',
    'Provide a concise summary of the key points of IRS Publication 946 on How to Depreciate Property, particularly focusing on the changes to depreciation limits and methods for the 2023 tax year?',
    'I have a client who is considering X. Here are the details of the situation. A. B. C. Can you provide an analysis?',
    'Give me a table describing the differences between Section 179 and bonus depreciation',
    'Draft a video script about X',
    'Create a step-by-step guideline for tax accountants to assist their clients in determining eligibility and calculating the Home Office Deduction using the Simplified Method for the 2023 tax year.',
    'Can you provide a list of questions that I can answer so you can give me a complete analysis about my client who is considering X, Y and Z.',
  ],
  'Email Reply': ['Please draft an email to clients explaining the importance of X', 'Draft a reply to this email from my client:'],
  'Pros and Cons': [
    "My client is considering changing corporate structure from X to Y during the 2024 tax year. Can you provide a list of pros and cons for this business structure change, specifically focusing on the tax implications and how it might affect the client's personal tax liability? Ask me any follow up questions that will help you provide a complete answer.",
    'Provide an outline for a course about X.',
  ],
  Marketing: ['Draft a facebook post for the topic X', 'Draft a LinkedIn Post for the topic X'],
  Training: ['Creating a course outline for X topic'],
};

const Prompts: React.FC = () => {
  const dispatch = useAppDispatch();
  const showExtraPrompts = useAppSelector(state => state.chatDashboard.showExtraPrompts);

  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [selectedPrompts, setSelectedPrompts] = useState(categories['All']);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
    setSelectedPrompts(categories[event.target.value]);
  };

  const handleSelectedPrompt = value => {
    dispatch(setSelectedPrompt(value));
    dispatch(setShowExtraPrompts(!showExtraPrompts));
    dispatch(setIsOpenChatList(true));
  };

  return (
    <Container className="list_containspace">
      <Row className="width-content">
        <h3>Prompt Library</h3>
        <Col md="12" sm="12" lg="6" xl="3" className="legend-spac">
          <fieldset>
            <legend>Select a category</legend>
            <FormGroup className="select-persone">
              <Input type="select" id="categorySelect" onChange={handleChange}>
                {Object.keys(categories).map(category => (
                  <option value={category}>{category}</option>
                ))}
              </Input>
            </FormGroup>
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <ListGroup className="prompt-list">
            {selectedCategory &&
              categories[selectedCategory].map((item: string, index: number) => (
                <ListGroupItem onClick={() => handleSelectedPrompt(item)} key={index}>
                  {item}
                </ListGroupItem>
              ))}
          </ListGroup>
          <div className="all-prompt-carousel">
            <PromptCarousel prompts={selectedPrompts} setPromptFieldValue={prompt => handleSelectedPrompt(prompt)} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Prompts;
