import { IAiChat } from 'app/shared/model/ai-chat.model';
import { IUserPrompt } from 'app/shared/model/user-prompt.model';

export interface IGeneratedMessage {
  id?: number;
  userRating?: number | null;
  userRatingReason?: string | null;
  formattedMessage?: string | null;
  aiModel?: string | null;
  rawMessage?: string | null;
  aiChat?: IAiChat | null;
  userPrompt?: IUserPrompt | null;
}

export const defaultValue: Readonly<IGeneratedMessage> = {};
