import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUserPrompt } from 'app/shared/model/user-prompt.model';
import { Persona } from 'app/shared/model/enumerations/persona.model';
import { getEntity, updateEntity, createEntity, reset } from './user-prompt.reducer';

export const UserPromptUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userPromptEntity = useAppSelector(state => state.userPrompt.entity);
  const loading = useAppSelector(state => state.userPrompt.loading);
  const updating = useAppSelector(state => state.userPrompt.updating);
  const updateSuccess = useAppSelector(state => state.userPrompt.updateSuccess);
  const personaValues = Object.keys(Persona);

  const handleClose = () => {
    navigate('/user-prompt' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...userPromptEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          promptPersona: 'AISalesAssistant',
          ...userPromptEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cpapilotApp.userPrompt.home.createOrEditLabel" data-cy="UserPromptCreateUpdateHeading">
            <Translate contentKey="cpapilotApp.userPrompt.home.createOrEditLabel">Create or edit a UserPrompt</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-prompt-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cpapilotApp.userPrompt.promptText')}
                id="user-prompt-promptText"
                name="promptText"
                data-cy="promptText"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('cpapilotApp.userPrompt.promptPersona')}
                id="user-prompt-promptPersona"
                name="promptPersona"
                data-cy="promptPersona"
                type="select"
              >
                {personaValues.map(persona => (
                  <option value={persona} key={persona}>
                    {translate('cpapilotApp.Persona.' + persona)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cpapilotApp.userPrompt.createdDate')}
                id="user-prompt-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="date"
              />
              <ValidatedField
                label={translate('cpapilotApp.userPrompt.updatedDate')}
                id="user-prompt-updatedDate"
                name="updatedDate"
                data-cy="updatedDate"
                type="date"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-prompt" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserPromptUpdate;
