import React, { useState, useEffect } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';

const PromptCarousel: React.FC = props => {
  const prompts: string[] = props.prompts;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  // Reset activeIndex and animation state on prompts change
  useEffect(() => {
    setActiveIndex(0);
    setAnimating(false);
  }, [prompts]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === prompts.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? prompts.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleSlideClick = prompt => {
    props.setPromptFieldValue(prompt);
  };

  const slides = props.prompts.map((prompt, index) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index}>
        <div
          className="carousel-content"
          onClick={() => handleSlideClick(prompt)}
          style={{ cursor: 'pointer' }} // Ensure the cursor changes to indicate interactivity
        >
          <p>{prompt}</p>
        </div>
        <CarouselCaption captionText={''} captionHeader={''} />
      </CarouselItem>
    );
  });

  return (
    <div className="carousel-container">
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={3000} ride="carousel">
        <CarouselIndicators items={prompts.map((_, i) => ({ id: i }))} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
};

export default PromptCarousel;
