import dayjs from 'dayjs';
import { IAiChat } from 'app/shared/model/ai-chat.model';

export interface IApi {
  id?: number;
  name?: string | null;
  docsPortalUrl?: string | null;
  prodUrl?: string | null;
  sandboxUrl?: string | null;
  lastIndexedTimestamp?: string | null;
  aiChats?: IAiChat[] | null;
}

export const defaultValue: Readonly<IApi> = {};
