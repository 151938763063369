import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './api.reducer';

export const ApiDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const apiEntity = useAppSelector(state => state.api.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="apiDetailsHeading">
          <Translate contentKey="cpapilotApp.api.detail.title">Api</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{apiEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="cpapilotApp.api.name">Name</Translate>
            </span>
          </dt>
          <dd>{apiEntity.name}</dd>
          <dt>
            <span id="docsPortalUrl">
              <Translate contentKey="cpapilotApp.api.docsPortalUrl">Docs Portal Url</Translate>
            </span>
          </dt>
          <dd>{apiEntity.docsPortalUrl}</dd>
          <dt>
            <span id="prodUrl">
              <Translate contentKey="cpapilotApp.api.prodUrl">Prod Url</Translate>
            </span>
          </dt>
          <dd>{apiEntity.prodUrl}</dd>
          <dt>
            <span id="sandboxUrl">
              <Translate contentKey="cpapilotApp.api.sandboxUrl">Sandbox Url</Translate>
            </span>
          </dt>
          <dd>{apiEntity.sandboxUrl}</dd>
          <dt>
            <span id="lastIndexedTimestamp">
              <Translate contentKey="cpapilotApp.api.lastIndexedTimestamp">Last Indexed Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {apiEntity.lastIndexedTimestamp ? (
              <TextFormat value={apiEntity.lastIndexedTimestamp} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/api" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/api/${apiEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApiDetail;
