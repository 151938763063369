import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserSubcription {
  id?: number;
  active?: boolean | null;
  orderId?: string | null;
  activeplan?: string | null;
  planStartDate?: dayjs.Dayjs | null;
  planEndDate?: dayjs.Dayjs | null;
  planLimit?: string | null;
  trial?: boolean | null;
  internalUser?: IUser | null;
}

export const defaultValue: Readonly<IUserSubcription> = {
  active: false,
  trial: false,
};
