import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Api from './api';
import ApiDetail from './api-detail';
import ApiUpdate from './api-update';
import ApiDeleteDialog from './api-delete-dialog';

const ApiRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Api />} />
    <Route path="new" element={<ApiUpdate />} />
    <Route path=":id">
      <Route index element={<ApiDetail />} />
      <Route path="edit" element={<ApiUpdate />} />
      <Route path="delete" element={<ApiDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApiRoutes;
