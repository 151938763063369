import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SubscriptionLimit from './subscription-limit';
import SubscriptionLimitDetail from './subscription-limit-detail';
import SubscriptionLimitUpdate from './subscription-limit-update';
import SubscriptionLimitDeleteDialog from './subscription-limit-delete-dialog';

const SubscriptionLimitRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SubscriptionLimit />} />
    <Route path="new" element={<SubscriptionLimitUpdate />} />
    <Route path=":id">
      <Route index element={<SubscriptionLimitDetail />} />
      <Route path="edit" element={<SubscriptionLimitUpdate />} />
      <Route path="delete" element={<SubscriptionLimitDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SubscriptionLimitRoutes;
