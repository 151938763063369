import dayjs from 'dayjs';
import { IGeneratedMessage } from 'app/shared/model/generated-message.model';
import { Persona } from 'app/shared/model/enumerations/persona.model';

export interface IUserPrompt {
  id?: number;
  promptText?: string;
  promptPersona?: Persona | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  generatedMessages?: IGeneratedMessage[] | null;
}

export const defaultValue: Readonly<IUserPrompt> = {};
