import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './citations.reducer';

export const CitationsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const citationsEntity = useAppSelector(state => state.citations.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="citationsDetailsHeading">
          <Translate contentKey="cpapilotApp.citations.detail.title">Citations</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.id}</dd>
          <dt>
            <span id="sessionId">
              <Translate contentKey="cpapilotApp.citations.sessionId">Session Id</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.sessionId}</dd>
          <dt>
            <span id="message">
              <Translate contentKey="cpapilotApp.citations.message">Message</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.message}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="cpapilotApp.citations.title">Title</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.title}</dd>
          <dt>
            <span id="imageUrl">
              <Translate contentKey="cpapilotApp.citations.imageUrl">Image Url</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.imageUrl}</dd>
          <dt>
            <span id="citationId">
              <Translate contentKey="cpapilotApp.citations.citationId">Citation Id</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.citationId}</dd>
          <dt>
            <span id="citationSiteName">
              <Translate contentKey="cpapilotApp.citations.citationSiteName">Citation Site Name</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.citationSiteName}</dd>
          <dt>
            <span id="citationUrl">
              <Translate contentKey="cpapilotApp.citations.citationUrl">Citation Url</Translate>
            </span>
          </dt>
          <dd>{citationsEntity.citationUrl}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.citations.generatedMessage">Generated Message</Translate>
          </dt>
          <dd>{citationsEntity.generatedMessage ? citationsEntity.generatedMessage.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/citations" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/citations/${citationsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CitationsDetail;
