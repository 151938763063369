import { IGeneratedMessage } from 'app/shared/model/generated-message.model';

export interface ICitations {
  id?: number;
  sessionId?: string | null;
  message?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  citationId?: string | null;
  citationSiteName?: string | null;
  citationUrl?: string | null;
  generatedMessage?: IGeneratedMessage | null;
}

export const defaultValue: Readonly<ICitations> = {};
