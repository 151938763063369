import './home.scss';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const Home = () => {
  const dispatch = useAppDispatch();
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && location.pathname != '/dashboard') {
      navigate('/dashboard');
    }
  }, [isAuthenticated]);

  return <Row></Row>;
};

export default Home;
