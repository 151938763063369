import useBreadcrumbs from 'use-react-router-breadcrumbs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';

interface Props {
  currentPath?: any;
  paramsName?: any;
}
const BreadCrumbTemplate = (props: Props) => {
  const DynamicBreadcrumb = ({ match }) => <span>{props.currentPath == match?.pathname && props.paramsName}</span>;

  const routes: any = [{ path: '/conversation/:conversation_id', breadcrumb: DynamicBreadcrumb }];
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <>
      <div className="d-flex">
        <Breadcrumb>
          {breadcrumbs.map(({ key, match, breadcrumb }) => (
            <BreadcrumbItem>
              <Link className="ms-1 text-decoration-none" key={key} to={match.pathname}>
                {breadcrumb}
              </Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </div>
    </>
  );
};
export default BreadCrumbTemplate;
