export enum ApiEndpointMethod {
  GET = 'GET',

  PUT = 'PUT',

  POST = 'POST',

  DELETE = 'DELETE',

  PATCH = 'PATCH',

  LIST = 'LIST',
}
