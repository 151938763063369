import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import GeneratedMessage from './generated-message';
import GeneratedMessageDetail from './generated-message-detail';
import GeneratedMessageUpdate from './generated-message-update';
import GeneratedMessageDeleteDialog from './generated-message-delete-dialog';

const GeneratedMessageRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<GeneratedMessage />} />
    <Route path="new" element={<GeneratedMessageUpdate />} />
    <Route path=":id">
      <Route index element={<GeneratedMessageDetail />} />
      <Route path="edit" element={<GeneratedMessageUpdate />} />
      <Route path="delete" element={<GeneratedMessageDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default GeneratedMessageRoutes;
